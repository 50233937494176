import {useEffect} from "react";
import {useFormikContext} from "formik";
import {useLinkResidentToAdmission} from "../../../page-admin-residents/hooks/useLinkResidentToAdmission";
import {useFetchLinkedResident} from "../../../page-admin-residents/hooks/useFetchLinkedResident";
import {NEW_RESIDENT} from "../SimilarResidents/types";
import {AdmissionDraft, FormValues, LinkedResident} from "./types";
import {useHistory} from "react-router";

/**
 * Hook to handle linking a resident to an admission when an admission is created
 */
export const useLinkResidentOnAdmissionCreation = (
  isAdmissionCreated: boolean,
  admissionDraft: AdmissionDraft | null,
  linkedResident: LinkedResident | null,
  isPointClickCareEnabled: boolean,
) => {
  const {mutate: linkAdmission} = useLinkResidentToAdmission();
  const {remove: removeLinkedResidentQuery} = useFetchLinkedResident(
    admissionDraft?.id,
  );
  const {values} = useFormikContext<FormValues>();
  const history = useHistory();

  const navigateToDashboardForNonPccAdmissions = () => {
    // Navigation is handled differently based on PCC status:
    // - PCC enabled: Navigation occurs in the PCC modal
    // - PCC disabled: Navigation happens here after resident linking
    // This separation ensures the PCC modal appears before dashboard navigation
    if (!isPointClickCareEnabled) {
      history.push("/admin/dashboard");
    }
  };

  useEffect(() => {
    if (!isAdmissionCreated) {
      return removeLinkedResidentQuery();
    }

    const similarResident = values?.resident_demographics?.similar_resident;

    const residentDemographicsFieldDoesNotExist = similarResident === undefined;

    if (residentDemographicsFieldDoesNotExist) {
      navigateToDashboardForNonPccAdmissions();

      return removeLinkedResidentQuery();
    }

    const isSimilarResidentSelected = similarResident !== NEW_RESIDENT;

    if (isSimilarResidentSelected) {
      // Use the selected similar resident or the linked resident's ID
      const residentId = similarResident || linkedResident?.id || "";

      linkAdmission({
        resident_id: residentId,
        admission_id: admissionDraft.id,
      });

      navigateToDashboardForNonPccAdmissions();

      return removeLinkedResidentQuery();
    } else if (!isPointClickCareEnabled) {
      // If no similar resident is selected and PCC is not enabled remove the query

      navigateToDashboardForNonPccAdmissions();
      return removeLinkedResidentQuery();
    }

    // Clean up function
    return removeLinkedResidentQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmissionCreated, isPointClickCareEnabled]);
};
